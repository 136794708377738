<template>
  <b-row>
    <b-col
      cols="12"
      md="8"
    >
      <b-form-group
        label="Arama"
        label-for="keyword"
      >
        <b-input-group>
          <b-form-input
            v-model="search.keyword"
            placeholder="Firma Adı, Lokasyon, Müşteri, Proje No.."
            @keydown.enter="searchData"
          />
          <b-input-group-append>
            <b-button
              v-if="search.keyword"
              variant="warning"
              @click="resetSearch"
            >
              <feather-icon icon="XIcon" />
            </b-button>
            <b-button
              variant="primary"
              @click="searchData"
            >
              <FeatherIcon icon="SearchIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      md="4"
    >
      <b-form-group
        label="Temsilci"
        label-for="id_users"
      >
        <v-select
          v-model="search.id_users"
          :options="users"
          label="title"
          :reduce="item => item.id"
          placeholder="Seçiniz"
          @input="searchData"
        >
          <template v-slot:option="option">
            <div>
              {{ option.title }}
            </div>
            <div v-if="option.user_type">
              <small class="text-primary">{{ option.user_type }}</small>
            </div>
          </template>
          <template v-slot:selected-option="option">
            <div>
              {{ option.title }}
            </div>
          </template>
        </v-select>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BInputGroup, BInputGroupAppend, BFormInput, BButton, BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'ProjectSearch',
  components: {
    BFormGroup,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BButton,
    BRow,
    BCol,
  },
  props: {
    searchData: {
      type: Function,
      required: true,
    },
    resetSearch: {
      type: Function,
      required: true,
    },
  },
  computed: {
    search() {
      return this.$store.getters['projects/search']
    },
    users() {
      return this.$store.getters['users/dataList']
    },
  },
  created() {
    this.getUsers()
  },
  methods: {
    getUsers() {
      this.$store.dispatch('users/getDataList', {
        select: [
          'users.id AS id',
          'users.name AS title',
          'user_types.title       AS user_type',
        ],
        where: {
          'users.status': 1,
        },
      })
    },
  },
}
</script>
